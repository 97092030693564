import { render, staticRenderFns } from "./SecondZoneSlides.vue?vue&type=template&id=0b9ecce6&"
import script from "./SecondZoneSlides.vue?vue&type=script&lang=js&"
export * from "./SecondZoneSlides.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports